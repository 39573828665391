.react-player__shadow{
    background: transparent !important;
}

.react-player__play-icon{
    /* border-style: solid;
    border-width: 50px !important;
    border-color: #680000 #680000 #680000 #680000 !important; */
    border: none !important;
    content: url('./images/mamma_play_circle_white_arrow.svg') !important;

}