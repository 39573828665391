@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*DAVIDS CSS*/

#NavBar li {
  float: left;
  margin-left: 6px;
}

#NavBar a {
  color: #680000;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.25em;
  margin: 0 2%;
}

#NavBar button {
  display: block;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-weight: bold;
  margin: 4px;
  background-color: #680000;
}

#NavBar button:hover {
  cursor: pointer;
}

#NavBar a:hover {
  padding-top: 5px;
  padding-bottom: 3px;
  border-bottom: 2px solid #680000;
  /* background-color: #680000;
  color: white;
  border-radius: 5px; */
}

div#accountcard {
  width: 92%;
  margin: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

div#accountcard input {
  margin-right: 3px;
  margin-bottom: 5px;
  padding: 2px;
}

div#accountcard button {
  margin-right: 3px;
  margin-bottom: 5px;
  padding: 2px 6px;
}

table {
  margin-left: 1%;
  margin-right: 10%;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.nowrap {
  margin: 0;
  width: 1px;
  white-space: normal;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #680000;
  color: white;
}

#userlistrole {
  margin: auto;
  width: 1400px;
}

#userlistrole h3 {
  margin-left: 190px;
}

#userlistrole button {
  margin-bottom: 10px;
  margin-left: 10px;
}

#userlistrole span:nth-child(even) {
  margin-left: 15px;
}

#userlistrole ul {
  margin: 1em 0 0 0;
  padding: 0;
  list-style-position: inside;
  width: 960px;
  margin: auto;
}

#userlistrole li {
  font-family: "DejaVu Sans", sans-serif;
  font-weight: bold;
  padding: 0.8em 1em 0 2em;
  overflow: hidden;
}

#userlistrole li:nth-child(odd) {
  background-color: rgba(104, 0, 0, 0.191);
}

#userlistrole li p {
  margin: 0 2em;
  overflow: hidden;
  float: left;
  position: relative;
  bottom: 1em;
}

/* END DAVIDS CSS*/

.mammabutton a {
  text-decoration: none;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #680000;
  border-radius: 10px;
}

.react-pdf__Page__canvas {
  display: inline !important;
}
