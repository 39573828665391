.noGrid {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  visibility: hidden;
}

.blackGrid {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* background-color: #000;
  background-image:
    linear-gradient(#333 1px, transparent 1px),
    linear-gradient(90deg, #333 1px, transparent 1px); */
}

.p0Grid {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-image: url("./P0.jpg");
  background-repeat: no-repeat;
}

.p1Grid {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-image: url("./P1.jpg");
  background-position: center top;
  background-repeat: no-repeat;
}

.inline {
  display: inline-block;
  background-color: white;
  vertical-align: top;
}

.TransparentBoard {
  position: relative;
  margin: 0px auto;
}

.BlackBoard {
  position: relative;
  margin: 0px auto;
  background-color: #000;
  background-image: linear-gradient(#333 1px, transparent 1px),
    linear-gradient(90deg, #333 1px, transparent 1px);
}

.Cell {
  background: #ccc;
  position: absolute;
}
.truePositive {
  color: #ffffff;
  background: #336600;
  width: 50%;
  /* margin-left: 25%; */
}
.trueAgain {
  color: #ffffff;
  background: #336600;
  width: 50%;
  /* margin-left: 25%; */
}
.falsePositive {
  color: #ffffff;
  background: #cc0000;
  width: 50%;
  /* margin-left: 25%; */
}

.mammabutton {
  margin-top: 20px;
  text-decoration: none;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #680000;
  border-radius: 10px;
}
