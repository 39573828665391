.Grid {
  width: 360px;
  height: 360px;
  background-image: url(./ModelNoLines.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0px 30px;
  display: flex;
  flex-wrap: wrap;
}
