/* line 3, ../sass/style.scss */
.outer-box {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.interaction-box {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
  text-align: center;
}
.outer-box .inner-box {
  /* background: #FD8698; */
  height: 100%;
  width: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity 0.5s;
}

/* line 34, ../sass/style.scss */
.outer-box:hover .inner-box {
  opacity: 0.1;
  transition: opacity 0.5s;
}
