.container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-image: url("../ExamGrid/P1.jpg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
}

.TransparentBoard {
  position: relative;
  margin: 0 auto;
  background-image: linear-gradient(#333 1px, transparent 1px),
    linear-gradient(90deg, #333 1px, transparent 1px);
}

.BlackBoard {
  position: relative;
  margin: 0 auto;
  background-color: #000;
  background-image: linear-gradient(#333 1px, transparent 1px),
    linear-gradient(90deg, #333 1px, transparent 1px);
}

.Cell {
  background: #ccc;
  position: absolute;
}

.inline {
  display: inline-block;
  width: 500px;
  height: 400px;
  padding: 5px;
  background-color: white;
  vertical-align: top;
}

div#modcardtwo {
  text-align: center;
  display: flex;
  justify-content: space-around;
}

div#modcarddata {
  text-align: left;
}

div#modcarddata h3 {
  text-align: center;
}
